import React, { ReactElement, useEffect } from 'react';
import * as Styled from './styles';
import { useLocation } from '@reach/router';
export const Nav = (): ReactElement => {
	const location = useLocation();
  

  let links = () => {

    if(location.pathname == '/' || location.pathname == '/pricing/' || location.pathname == '/pricing'){
      return(<Styled.NavButton href="https://sketchwow.com/pricing">Order now</Styled.NavButton>)
    }else if(location.pathname == '/trial/' || location.pathname == '/trial'){
      return(<Styled.NavButton id="order-now-link"  href="https://checkout.sketchwow.com/?add-to-cart=2064">Order now</Styled.NavButton>)
    /*}else if(location.pathname == '/sale/' || location.pathname == '/sale' || location.pathname == '/save/' || location.pathname == '/save' 
              || location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal' || location.pathname == '/lifetime/' || location.pathname == '/lifetime'
              || location.pathname == '/deal/' || location.pathname == '/deal' || location.pathname == '/promo/' || location.pathname == '/promo'  || location.pathname == '/saves/' || location.pathname == '/saves'){
      return(<Styled.NavButton  href="#DealPricing">Order now</Styled.NavButton>)*/
    }else if(location.pathname == '/sale-test/' || location.pathname == '/sale-test'){
      return(<Styled.NavButton  href="#DealPricing">Order now</Styled.NavButton>)
    // }else if(location.pathname == '/sale/' || location.pathname == '/sale'){
    //   return(<Styled.NavButton id="order-now-link"  href="https://checkout.sketchwow.com/?add-to-cart=407&cg2=1">Order now</Styled.NavButton>)
    }else if((location.pathname == '/reviews/' || location.pathname == '/reviews')
              || (location.pathname == '/privacy-policy/' || location.pathname == '/privacy-policy')
              || (location.pathname == '/terms-service/' || location.pathname == '/terms-service')
              || (location.pathname == '/cookie-policy/' || location.pathname == '/cookie-policy')
              || (location.pathname.includes('download'))){
      return (<></>)
    }else{
      return(<Styled.NavButton id="order-now-link"  href="https://checkout.sketchwow.com/?add-to-cart=407">Order now</Styled.NavButton>)
    }

  }

  useEffect(()=>{

    const deal_qstr =  window.location.href.split('?')[1];
    let link = "https://checkout.sketchwow.com?add-to-cart=407";
    if(location.pathname == '/' || location.pathname == '/pricing/' || location.pathname == '/pricing' || location.pathname == '/faq/' || location.pathname == '/faq'){
      link = "https://sketchwow.com/pricing"
    }else if(location.pathname == '/trial/' || location.pathname == '/trial'){
      link = "https://checkout.sketchwow.com/?add-to-cart=2064" 
    }else if(location.pathname == '/sale/' || location.pathname == '/sale'){
       link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sl" // link = "#DealPricing"; //
    }else if(location.pathname == '/save/' || location.pathname == '/save'){
       link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=sv" // link = "#DealPricing"; //
    }else if(location.pathname == '/offer/' || location.pathname == '/offer'){
       link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=of" // link = "#DealPricing"; //
    }else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime'){
        link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=lt";// link = "#DealPricing"; //
    }else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal'){
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=ltd";// link = "#DealPricing"; //
    }else if(location.pathname == '/deal/' || location.pathname == '/deal'){
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=dl";  // link = "#DealPricing"; //
    }else if((location.pathname == '/promo/' || location.pathname == '/promo') && localStorage.getItem('refid')){
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr&coupon_code=save10percent&ref="+localStorage.getItem('refid');// link = "#DealPricing"; //
    }else if(location.pathname == '/promo/' || location.pathname == '/promo'){
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=pr";  //link = "#DealPricing";  //
    // }else if(location.pathname == '/home-new/' || location.pathname == '/home-new'){
    //   link = "https://sketchwow.com/pricing-new/";  

    }else{
     link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1"  //link = "#DealPricing";  //
    }

    
  
   if(typeof deal_qstr !== 'undefined' && document.getElementById("order-now-link") !== null){
     document.getElementById("order-now-link").href = link + "&" + deal_qstr;
    }else   if(document.getElementById("nav-list-link") !== null  && document.getElementById("order-now-link") !== null){
      document.getElementById("order-now-link").href = link 
    }
   }, []);


  return(<Styled.Nav>
    <Styled.NavList>
      <Styled.NavItem>
  		
  		{links()}

      </Styled.NavItem>
    </Styled.NavList>
  </Styled.Nav>)
};
